<template>
  <v-sheet
    width="100%"
    height="200px"
    color="primary"
    tile
  >
    <v-img
      v-if="!isMobile()"
      :key="image"
      class="hidden-sm-and-down"
      :src="image"
      gradient="to bottom, rgba(0,117,131,.9), rgba(0,117,131,.5)"
      height="100%"
    >
      <v-container
        class="fill-height white--text"
      >
        <v-slide-x-transition appear>
          <v-col>
            <div
              v-bind:class="{ 'text-center':(!shouldRightAlignHeader()),'text-right':(shouldRightAlignHeader())}"
            >
              <div
                class="title font-weight-medium ma-auto"
                style="width:500px !important"
              />
              {{ $t("header.subtitle") }}
            </div>
          </v-col>
        </v-slide-x-transition>
      </v-container>
    </v-img>
  </v-sheet>
</template>

<script>
  import VueScreenSize from 'vue-screen-size'
  export default {
    mixins: [VueScreenSize.VueScreenSizeMixin],
    computed: {

      image () {
        return '/static/' + (this.$route.meta && this.$route.meta.hero ? this.$route.meta.hero : this.$route.name) + '-hero.jpg'
      },

    },

    methods: {
      isMobile () {
        if (this.$vssWidth <= 960) {
          return true
        } else {
          return false
        }
      },
      shouldRightAlignHeader () {
        if (this.$vssWidth <= 1100) {
          return true
        } else {
          return false
        }
      },
    },

  }
</script>
